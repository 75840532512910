<!-- <template>
    <div class="login-container">
        <h1 style="color: #fff">用户登录</h1>
        <div class="form">
            <el-input prefix-icon="el-icon-user" placeholder="请输入账号" v-model="username" size="large" autocomplete="off"
                autofocus="off" @keyup.enter="handleclick()"></el-input>
            <el-input prefix-icon="el-icon-lock" show-password="false" placeholder="请输入密码" v-model="password" size="large"
                autocomplete="off" @keyup.enter="handleclick()"></el-input>
            <div class="login-option">
                <el-radio-group v-model="accounttype">
                    <el-radio-button label="1" border>运维人员</el-radio-button>
                    <el-radio-button label="3" border>星火账户</el-radio-button>
                </el-radio-group>
                <el-button type="primary" @click="handleclick()">登录</el-button>
            </div>
        </div>
    </div>
</template> -->
<template>
    <section class="usr_login">
        <el-form class="el_form_override">
            <h3>
                闲牛租后台管理系统
            </h3>
            <el-form-item>
                <el-input prefix-icon="User" placeholder="请输入账号" v-model="username" size="large" autocomplete="off"
                          :autofocus="false" @keyup.enter="handleclick()" clearable />
            </el-form-item>
            <el-form-item>
                <el-input prefix-icon="Lock" :show-password="true" placeholder="请输入密码" v-model="password" size="large"
                          autocomplete="off" @keyup.enter="handleclick()" />
            </el-form-item>
            <el-form-item>
                <div class="login-option">
                    <el-radio-group v-model="accounttype">
                        <el-radio-button value="1" label="运维人员" />
                        <el-radio-button value="3" label="星火账户" />
                    </el-radio-group>
                    <el-button type="primary" @click="handleclick()">登录</el-button>
                </div>
            </el-form-item>
        </el-form>
    </section>
</template>
<!-- <style scoped>
h1 {
    margin-top: 10%;
}
.input-block {
    background: #2d3a4b;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 30%;
}
.login-option {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}
.el-input {
    border-radius: 3px;
    color: #fff;
}
.el-input {
    margin: 10px;
    width: 85%;
}
.el-input :deep() .el-input__inner {
    background-color: #273241;
    line-height: 50px;
    height: 50px;
    border: 1px solid #7e7777;
    color: #fff;
}
.el-input :deep() .el-input__icon {
    line-height: 50px;
}
.login-container {
    min-height: 100%;
    width: 100%;
    background-color: #6e777a;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
</style> -->
<script>
//import { ElMessage } from 'element-plus';
import Cookies from 'js-cookie'
import constant from '@/constant';
export default {
    data() {
        return {
            username: '',
            password: '',
            accounttype: '1',
        }
    },
    created: function () {
        console.log(Cookies);
        let cookies = Cookies.get("token");
        if (cookies) {
            let role = Cookies.get("role");
            console.log(role)
            if(role == 5){
                this.$router.push('/SparkFire');
            }else{
                let redirectPath = this.$route.query.redirect || "/home";
                this.$router.push(redirectPath);
            }
        }
    },
    methods: {
        handleclick() {
            let acctype = this.accounttype
            this.axios.get(constant.login_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    username: this.username,
                    userpass: this.password,
                    accounttype: this.accounttype,
                }
            }).then((response) => {
                console.log(response.data);
                console.log("name--" + response.data.name);
                console.log("id--" + response.data.id);
                console.log("session--" + response.data.session);
                console.log("role--" + response.data.role);
                if (response.data.message == "NOUSER") {
                    this.$message.error("该用户不存在或已失效");
                    return false;
                } else if (response.data.message == "ERROR") {
                    this.$message.error("请填写用户名和密码");
                    return false;
                } else if (response.data.message == "TEST") {
                    return false;
                } else {
                    //this.$message.success("登录成功");
                    //console.log(Date.parse(new Date())/1000);
                    //this.$cookies.set("token",response.data.token,"60s");
                    //this.$cookies.set("userid",response.data.ID,"60s");
                    Cookies.set('name', response.data.name, { expires: 1 });
                    Cookies.set('token', response.data.token, { expires: 1 });
                    Cookies.set('id', response.data.id, { expires: 1 });
                    Cookies.set('role', response.data.role, { expires: 1 });
                    Cookies.set("session", response.data.session, { expires: 1 });
                    Cookies.set('IsMerchant', response.data.IsMerchant, { expires: 1 });
                    if (response.data.mid)
                        Cookies.set('mid', response.data.mid, { expires: 1 })
                    if (response.data.role == 2 || response.data.role == 4) {
                        Cookies.set('patharr', response.data.patharr, { expires: 1 });
                    }
                    const redirectPath = this.$route.query.redirect || "/home";
                    console.log(acctype)
                    if (acctype == 3) {
                        this.$router.push('/SparkFire');
                    } else {
                        this.$router.push(redirectPath);
                    }
                }
            })
            /*
            constant.username = this.username;
            if(this.username != 'admin' || this.password != '123456'){
                ElMessage.error({
                    type:'error',
                    message:'用户名或者密码错误'
                });
                return false;
            }
            const redirectPath = this.$route.query.redirect || "/home";
            this.$router.push(redirectPath);*/
            //var url = 'http://localhost/mobmall/data.php?userid=' + this.username;
            //console.log(url);
            /*
            this.axios.get(constant.login_url,{
                    headers: {'Content-Type': 'application/json'},
                    params: {
                        username: this.username,
                        userpass: this.password,
                    }
                }).then((response)=>{
                    if(response.data == "ERROR"){
                        ElMessage.error({
                            message: "请填写用户名和密码"
                        });
                    }else if(response.data == "NOEXIST"){
                        ElMessage.error({
                            message: "该用户不存在"
                        });
                    }else{
                         var userinfo = response.data;
                         constant.userinfo = userinfo;
                         constant.username = userinfo['Name'];
                         console.log(constant.username);
                         this.setCookie("username",userinfo['Name'],30);
                         const redirectPath = this.$route.query.redirect || "/home";
                         this.$router.push(redirectPath);
                    }
            });
            //navi.user = this.username;
            //const redirectPath = this.$route.query.redirect || "/home";
            //this.$router.push(redirectPath);*/
        },
        setCookie(name, value, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 12 * 60 * 60 * 1000));
            var expires = "expires=" + d.toGMTString();
            document.cookie = name + "=" + value + ";" + expires;
        },
        getCookie(name) {
            let ret, m;
            if (typeof name == "string" && name !== "") {
                if ((m = String(document.cookie).match(new RegExp('(?:^| )' + name + '(?:(?:=([^;]*))|;|$)')))) {
                    ret = m[1] ? decodeURIComponent(m[1]) : ""
                }
            }
            return ret
        },
        DoLogin() {
            var username = getCookie("username");
            if (username) {
                const redirectPath = this.$route.query.redirect || "/home";
                this.$router.push(redirectPath);
            }
            function getCookie(name) {
                let ret, m;
                if (typeof name == "string" && name !== "") {
                    if ((m = String(document.cookie).match(new RegExp('(?:^| )' + name + '(?:(?:=([^;]*))|;|$)')))) {
                        ret = m[1] ? decodeURIComponent(m[1]) : ""
                    }
                }
                return ret
            }
        },
    }
}
</script>
<style scoped>
.usr_login {
    background-color: #2d3a4b;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.el_form_override {
    min-width: 300px;
    padding: 50px;
    background-color: rgb(41, 51, 67);
    border: 1px solid rgb(50, 60, 74);
    border-radius: 5px;
}

.el_form_override h3 {
    font-size: 26px;
    color: #eee;
    margin-bottom: 40px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.el_form_override h3 .el-icon {
    margin-right: 10px;
}
</style>
